/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./montserrat-v14-latin/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('./montserrat-v14-latin/montserrat-v14-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./montserrat-v14-latin/montserrat-v14-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./montserrat-v14-latin/montserrat-v14-latin-regular.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./montserrat-v14-latin/montserrat-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('./montserrat-v14-latin/montserrat-v14-latin-500.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./montserrat-v14-latin/montserrat-v14-latin-500.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-500.woff') format('woff'),
    /* Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-500.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./montserrat-v14-latin/montserrat-v14-latin-500.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./montserrat-v14-latin/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('./montserrat-v14-latin/montserrat-v14-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./montserrat-v14-latin/montserrat-v14-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-700.woff') format('woff'),
    /* Modern Browsers */
      url('./montserrat-v14-latin/montserrat-v14-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./montserrat-v14-latin/montserrat-v14-latin-700.svg#Montserrat')
      format('svg'); /* Legacy iOS */
}
